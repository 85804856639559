export const INTERNAL_SERVER_ERROR = 500
export const NOT_IMPLEMENTED_ERROR = 501
export const BAD_GATEWAY_ERROR = 502
export const SERVICE_UNAVAILABLE_ERROR = 503
export const GATEWAY_TIMEOUT_ERROR = 504
export const HTTP_VERSION_NOT_SUPPORTED_ERROR = 505
export const BAD_REQUEST_ERROR = 400
export const UNAUTHORIZED_ERROR = 401
export const FORBIDDEN_ERROR = 403
export const NOT_FOUND_ERROR = 404
export const CONFLICT_ERROR = 409