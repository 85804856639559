import moment from 'moment'
import {FORMAT_DATE, FORMAT_DATETIME, FORMATE_DATE_NO_HOURS} from '../constants'

export const formatDate = (date: string, format: string = FORMAT_DATE) => {
  const momentDate = moment(date)
  if (momentDate.isValid()) {
    return momentDate.format(format)
  }
  return ''
}

export const formatDateTime = (date: string, format: string = FORMAT_DATETIME) => {
  const momentDate = moment(date)
  if (momentDate.isValid()) {
    return momentDate.format(format)
  }
  return ''
}

export const formatDateNoHours = (date: string, format: string = FORMATE_DATE_NO_HOURS) => {
  return moment(date).format(format)
}
