export const PERMISSIONS = {
  view_organization_list: 'view_organization_list',
  add_org: 'add_org',
  edit_org_details: 'edit_org_details',
  view_site_list: 'view_site_list',
  add_site: 'add_site',
  edit_site: 'edit_site',
  view_site_locale_list: 'view_site_locale_list',
  add_locale: 'add_locale',
  edit_locale: 'edit_locale',
  view_product_list: 'view_product_list',
  edit_product_details: 'edit_product_details',
  view_warehouse_list: 'view_warehouse_list',
  edit_warehouse: 'edit_warehouse',
  view_product_property_list: 'view_product_property_list',
  add_product_property: 'add_product_property',
  edit_product_property: 'edit_product_property',
  view_default_property_value_list: 'view_default_property_value_list',
  add_default_property_value: 'add_default_property_value',
  edit_default_property_value: 'edit_default_property_value',
  view_marketplace_list: 'view_marketplace_list',
  add_marketplace: 'add_marketplace',
  edit_marketplace: 'edit_marketplace',
  view_marketplace_relationship_list: 'view_marketplace_relationship_list',
  add_marketplace_relationship: 'add_marketplace_relationship',
  edit_marketplace_relationship: 'edit_marketplace_relationship',
  view_product_category_mapping_list: 'view_product_category_mapping_list',
  add_product_category_mapping: 'add_product_category_mapping',
  edit_product_category_mapping: 'edit_product_category_mapping',
  edit_product_category_detail: 'edit_product_category',
  view_product_property_mapping_list: 'view_product_property_mapping_list',
  add_product_property_mapping: 'add_product_property_mapping',
  edit_product_property_mapping: 'edit_product_property_mapping',
  view_product_property_value_mapping_list:
    'view_product_property_value_mapping_list',
  add_product_property_value_mapping: 'add_product_property_value_mapping',
  edit_product_property_value_mapping: 'edit_product_property_value_mapping',
  view_courier_mapping_list: 'view_courier_mapping_list',
  add_courier_mapping: 'add_courier_mapping',
  edit_courier_mapping: 'edit_courier_mapping',
  view_delivery_mapping_list: 'view_delivery_mapping_list',
  add_delivery_mapping: 'add_delivery_mapping',
  edit_delivery_mapping: 'edit_delivery_mapping',
  view_assign_products_to_marketplaces: 'view_assign_products_to_marketplaces',
  list_de_list_products: 'list_de_list_products',
  view_listing_status: 'view_listing_status',
  re_list_products: 're_list_products',
  view_order_list: 'view_order_list',
  view_product_categories: 'view_product_categories',
  edit_marketplace_selling_price_adjustment:
    'edit_marketplace_selling_price_adjustment',
  view_return_order_list: 'view_return_order_list',
  // UPS mode
  ups_view_application_list: 'view_application_list',
  ups_add_application: 'add_application',
  ups_edit_application: 'edit_application',
  ups_view_application_details: 'view_application_details',
  ups_view_module_list: 'view_module_list',
  ups_add_module: 'add_module',
  ups_edit_module: 'edit_module',
  ups_view_module_details: 'view_module_details',
  ups_view_role_list: 'view_role_list',
  ups_add_role: 'add_role',
  ups_edit_role: 'edit_role',
  ups_view_role_details: 'view_role_details',
  ups_edit_role_permissions: 'edit_role_permissions',
  ups_view_role_permissions: 'view_role_permissions',
  ups_view_roles_to_assign_list: 'view_roles_to_assign_list',
  ups_add_roles_to_assign: 'add_roles_to_assign',
  ups_edit_roles_to_assign: 'edit_roles_to_assign',
  ups_view_roles_to_assign_details: 'view_roles_to_assign_details',
  ups_view_permission_list: 'view_permission_list',
  ups_add_permission: 'add_permission',
  ups_edit_permission: 'edit_permission',
  ups_view_permission_details: 'view_permission_details',
  ups_view_organization_list: 'view_organization_list',
  ups_add_organization: 'add_organization',
  ups_edit_organization: 'edit_organization',
  ups_view_organization_details: 'view_organization_details',
  ups_import_by_CSV: 'import_by_CSV',
  ups_view_site_list: 'view_site_list',
  ups_add_site: 'add_site',
  ups_edit_site: 'edit_site',
  ups_view_site_details: 'view_site_details',
  ups_view_locale_list: 'view_locale_list',
  ups_add_locale: 'add_locale',
  ups_edit_locale: 'edit_locale',
  ups_view_locale_details: 'view_locale_details',
  ups_view_user_list: 'view_user_list',
  ups_add_user: 'add_user',
  ups_edit_user: 'edit_user',
  ups_view_user_details: 'view_user_details',
  ups_view_user_permissions_list: 'view_user_permissions_list',
  ups_edit_user_permission: 'edit_user_permission',
  ups_view_user_permission_details: 'view_user_permission_details',
  ups_add_user_permission: 'add_user_permission',
}
