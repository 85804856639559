import {LabelValue, MenuItemType} from '../interface'
import {
  ROUTE_ASSIGNED_PRODUCTS_TO_MARKETPLACES,
  ROUTE_COURIER_MAPPINGS,
  ROUTE_DEFAULT_PROPERTY_VALUE,
  ROUTE_DELIVERY_TYPE_MAPPINGS,
  ROUTE_LISTING_STATUS,
  ROUTE_MARKETPLACE,
  ROUTE_MARKETPLACE_RELATIONSHIP,
  ROUTE_ORDER,
  ROUTE_ORDER_RETURN,
  ROUTE_ORG,
  ROUTE_PRODUCT,
  ROUTE_PRODUCT_CATEGORIES,
  ROUTE_PRODUCT_CATEGORY_MAPPINGS,
  ROUTE_PRODUCT_PROPERTIES,
  ROUTE_PRODUCT_PROPERTY_MAPPINGS,
  ROUTE_UPS,
  ROUTE_WAREHOUSE,
} from './routes'

import {PERMISSIONS} from './permissions'

const LABELS: LabelValue = {
  management: 'management_title',
  organizations: 'organization_title',
  warehouse: 'navigator_title_warehouse',
  products: 'navigator_title_products',
  productProperties: 'navigator_title_products_properties',
  configuration: 'navigator_title_configuration',
  // users: 'navigator_title_users',
  // aggregators: 'navigator_title_aggregators',
  marketplaces: 'navigator_title_marketplaces',
  marketplaceRelationships: 'navigator_title_marketplace_relationships',
  assignedProducts: 'navigator_title_products_marketplaces',
  orders: 'navigator_title_orders',
  listingStatus: 'navigator_title_listing_status',
  defaultPropertyValue: 'navigator_title_default_property_value',
  productCategoryMappings: 'navigator_title_product_category_mappings',
  courierMappings: 'navigate_title_courier_mappings',
  marketplaceProductCategories: 'navigate_title_marketplace_product_categories',
  returnsRefunds: 'navigator_title_returns_refunds',
  deliveryTypeMappings: 'navigator_title_delivery_type_mappings',
  productCategories: 'navigator_title_product_categories',
  returnOrders: 'navigator_title_return_orders',
}

export const MENU_ITEMS: MenuItemType[] = [
  {
    key: 'apps-management',
    label: LABELS.management,
    isTitle: false,
    icon: 'uil-suitcase',
    permissions: [
      PERMISSIONS.view_organization_list,
      PERMISSIONS.view_product_property_list,
      PERMISSIONS.view_warehouse_list,
      PERMISSIONS.view_product_list,
      PERMISSIONS.view_default_property_value_list,
      PERMISSIONS.view_marketplace_list,
      PERMISSIONS.view_marketplace_relationship_list,
      PERMISSIONS.view_product_category_mapping_list,
      PERMISSIONS.view_product_property_mapping_list,
      PERMISSIONS.view_delivery_mapping_list,
      PERMISSIONS.view_courier_mapping_list,
      PERMISSIONS.view_assign_products_to_marketplaces,
      PERMISSIONS.view_listing_status,
      PERMISSIONS.view_order_list,
      PERMISSIONS.view_product_categories,
      PERMISSIONS.view_return_order_list
    ],
    children: [
      {
        key: 'apps-organizations',
        label: LABELS.organizations,
        url: ROUTE_ORG.ROOT,
        parentKey: 'apps-management',
        permissions: [PERMISSIONS.view_organization_list],
      },
      {
        key: 'apps-product-properties',
        label: LABELS.productProperties,
        url: ROUTE_PRODUCT_PROPERTIES.ROOT,
        parentKey: 'apps-management',
        permissions: [PERMISSIONS.view_product_property_list],
      },
      {
        key: 'apps-warehouse',
        label: LABELS.warehouse,
        url: ROUTE_WAREHOUSE.ROOT,
        parentKey: 'apps-management',
        permissions: [PERMISSIONS.view_warehouse_list],
      },
      {
        key: 'apps-products',
        label: LABELS.products,
        url: ROUTE_PRODUCT.ROOT,
        parentKey: 'apps-management',
        permissions: [PERMISSIONS.view_product_list],
      },
      {
        key: 'apps-default-property-values',
        label: LABELS.defaultPropertyValue,
        url: ROUTE_DEFAULT_PROPERTY_VALUE.ROOT,
        parentKey: 'apps-management',
        permissions: [PERMISSIONS.view_default_property_value_list],
      },
      // {
      //   key: 'apps-aggregators',
      //   label: LABELS.aggregators,
      //   url: ROUTE_AGGREGATOR.ROOT,
      //   parentKey: 'apps-management',
      // },
      {
        key: 'apps-marketplaces',
        label: LABELS.marketplaces,
        url: ROUTE_MARKETPLACE.ROOT,
        parentKey: 'apps-management',
        permissions: [PERMISSIONS.view_marketplace_list],
      },
      {
        key: 'apps-marketplace-relationships',
        label: LABELS.marketplaceRelationships,
        url: ROUTE_MARKETPLACE_RELATIONSHIP.ROOT,
        parentKey: 'apps-management',
        permissions: [PERMISSIONS.view_marketplace_relationship_list],
      },
      {
        key: 'apps-product-categories',
        label: LABELS.productCategories,
        url: ROUTE_PRODUCT_CATEGORIES.ROOT,
        parentKey: 'apps-management',
        permissions: [PERMISSIONS.view_product_categories],
      },
      {
        key: 'apps-product-category-mappings',
        label: LABELS.productCategoryMappings,
        url: ROUTE_PRODUCT_CATEGORY_MAPPINGS.ROOT,
        parentKey: 'apps-management',
        permissions: [PERMISSIONS.view_product_category_mapping_list],
      },
      {
        key: 'apps-marketplace-product-categories',
        label: LABELS.marketplaceProductCategories,
        url: ROUTE_PRODUCT_PROPERTY_MAPPINGS.ROOT_NO_PARAMS,
        parentKey: 'apps-management',
        permissions: [PERMISSIONS.view_product_category_mapping_list],
      },
      {
        key: 'apps-delivery-type-mappings',
        label: LABELS.deliveryTypeMappings,
        url: ROUTE_DELIVERY_TYPE_MAPPINGS.ROOT,
        parentKey: 'apps-management',
        permissions: [PERMISSIONS.view_delivery_mapping_list],
      },
      {
        key: 'apps-courier-mappings',
        label: LABELS.courierMappings,
        url: ROUTE_COURIER_MAPPINGS.ROOT,
        parentKey: 'apps-management',
        permissions: [PERMISSIONS.view_courier_mapping_list],
      },
      {
        key: 'apps-assigned-products',
        label: LABELS.assignedProducts,
        url: ROUTE_ASSIGNED_PRODUCTS_TO_MARKETPLACES.ROOT,
        parentKey: 'apps-management',
        permissions: [PERMISSIONS.view_assign_products_to_marketplaces],
      },
      {
        key: 'apps-listing-status',
        label: LABELS.listingStatus,
        url: ROUTE_LISTING_STATUS.ROOT,
        parentKey: 'apps-management',
        permissions: [PERMISSIONS.view_listing_status],
      },
      {
        key: 'apps-orders',
        label: LABELS.orders,
        url: ROUTE_ORDER.ROOT,
        parentKey: 'apps-management',
        permissions: [PERMISSIONS.view_order_list],
      },
      {
        key: 'apps-return-orders',
        label: LABELS.returnOrders,
        url: ROUTE_ORDER_RETURN.ROOT,
        parentKey: 'apps-management',
        permissions: [PERMISSIONS.view_return_order_list],
      },
      // {
      //   key: 'apps-returnrefund',
      //   label: LABELS.returnsRefunds,
      //   url: '/returnrefund',
      //   parentKey: 'apps-management',
      // },
    ],
  },
  // {
  //   key: 'apps-configuration',
  //   label: LABELS.configuration,
  //   isTitle: false,
  //   icon: 'dripicons-gear',
  //   children: [
  //     {
  //       key: 'app-users',
  //       label: LABELS.users,
  //       url: ROUTE_USER.ROOT,
  //       parentKey: 'apps-configuration',
  //     },
  //   ],
  // },
]

export const UPS_MENU_ITEMS: MenuItemType[] = [
  {
    key: 'ups-application-setup',
    label: 'Application Setup',
    isTitle: false,
    icon: 'dripicons-gear',
    permissions: [
      PERMISSIONS.ups_view_application_list,
      PERMISSIONS.ups_view_module_list,
      PERMISSIONS.ups_view_role_list,
      PERMISSIONS.ups_view_permission_list,
    ],
    children: [
      {
        key: 'ups-applications',
        label: 'Applications',
        url: ROUTE_UPS.APPLICATION_SETUP.APPLICATION,
        parentKey: 'ups-application-setup',
        isTitle: false,
        permissions: [PERMISSIONS.ups_view_application_list],
      },
      {
        key: 'ups-modules',
        label: 'Modules',
        url: ROUTE_UPS.APPLICATION_SETUP.MODULES,
        parentKey: 'ups-application-setup',
        isTitle: false,
        permissions: [PERMISSIONS.ups_view_module_list],
      },
      {
        key: 'ups-roles',
        label: 'Roles',
        url: ROUTE_UPS.APPLICATION_SETUP.ROLES,
        parentKey: 'ups-application-setup',
        isTitle: false,
        permissions: [PERMISSIONS.ups_view_role_list],
      },
      {
        key: 'ups-permissions',
        label: 'Permissions',
        url: ROUTE_UPS.APPLICATION_SETUP.PERMISSIONS,
        parentKey: 'ups-application-setup',
        isTitle: false,
        permissions: [PERMISSIONS.ups_view_permission_list],
      },
    ],
  },
  {
    key: 'ups-organization-setup',
    label: 'Organization Setup',
    isTitle: false,
    icon: 'dripicons-network-3',
    permissions: [
      PERMISSIONS.ups_view_organization_list,
      PERMISSIONS.ups_view_site_list,
      PERMISSIONS.ups_view_locale_list,
    ],
    children: [
      {
        key: 'ups-organizations',
        label: 'Organizations',
        url: ROUTE_UPS.ORGANIZATION_SETUP.ORGANIZATIONS,
        parentKey: 'ups-organization-setup',
        isTitle: false,
        permissions: [PERMISSIONS.ups_view_organization_list],
      },
      {
        key: 'ups-sites',
        label: 'Sites',
        url: ROUTE_UPS.ORGANIZATION_SETUP.SITES,
        parentKey: 'ups-organization-setup',
        isTitle: false,
        permissions: [PERMISSIONS.ups_view_site_list],
      },
      {
        key: 'ups-locales',
        label: 'Locales',
        url: ROUTE_UPS.ORGANIZATION_SETUP.LOCALES,
        parentKey: 'ups-organization-setup',
        isTitle: false,
        permissions: [PERMISSIONS.ups_view_locale_list],
      },
    ],
  },
  {
    key: 'ups-user-management',
    label: 'User Management',
    isTitle: false,
    icon: 'dripicons-user-group',
    permissions: [
      PERMISSIONS.ups_view_user_list,
      PERMISSIONS.ups_view_user_permissions_list,
      PERMISSIONS.ups_view_roles_to_assign_list
    ],
    children: [
      {
        key: 'ups-users',
        label: 'Users',
        url: ROUTE_UPS.USER_MANAGEMENT.USERS,
        parentKey: 'ups-user-management',
        isTitle: false,
        permissions: [PERMISSIONS.ups_view_user_list],
      },
      {
        key: 'ups-user-permissions',
        label: 'User Permissions',
        url: ROUTE_UPS.USER_MANAGEMENT.USER_PERMISSIONS,
        parentKey: 'ups-user-management',
        isTitle: false,
        permissions: [PERMISSIONS.ups_view_user_list],
      },
      {
        key: 'ups-roles-to-assign',
        label: 'Roles to Assign',
        url: ROUTE_UPS.USER_MANAGEMENT.ROLES_TO_ASSIGN,
        parentKey: 'ups-user-management',
        isTitle: false,
        permissions: [PERMISSIONS.ups_view_roles_to_assign_list],
      },
    ],
  },
]
