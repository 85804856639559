export type Config = {
  REACT_APP_UPS_BASE_URL: string
  REACT_APP_URL_KEYCLOAK: string
  REACT_APP_REALM: string
  REACT_APP_CLIENT_ID: string
  REACT_APP_USER_GUIDE_URL: string
  REACT_APP_RITHUM_USER_GUIDE_URL: string
  REACT_CHANNEL_ADVISOR_ENABLED: boolean
}

export class Configs {
  private _config: {
    REACT_APP_UPS_BASE_URL: string
    REACT_APP_URL_KEYCLOAK: string
    REACT_APP_REALM: string
    REACT_APP_CLIENT_ID: string
    REACT_APP_USER_GUIDE_URL: string
    REACT_APP_RITHUM_USER_GUIDE_URL: string
    REACT_CHANNEL_ADVISOR_ENABLED: boolean
  }

  constructor() {
    this._config = {
      REACT_APP_UPS_BASE_URL: '',
      REACT_APP_URL_KEYCLOAK: '',
      REACT_APP_REALM: '',
      REACT_APP_CLIENT_ID: '',
      REACT_APP_USER_GUIDE_URL: '',
      REACT_APP_RITHUM_USER_GUIDE_URL: 'https://hutgroupnorthwich.sharepoint.com/:p:/t/ProductUX/EW0ylUzAiKRPkKiYgB8i5hUBYM0etVcsPr07zm_yJNYL_A?e=fXLYUC&isSPOFile=1&clickparams=eyJBcHBOYW1lIjoiVGVhbXMtRGVza3RvcCIsIkFwcFZlcnNpb24iOiI1MC8yNDA1MzEwMTQyMSIsIkhhc0ZlZGVyYXRlZFVzZXIiOmZhbHNlfQ%3D%3D',
      REACT_CHANNEL_ADVISOR_ENABLED: true,
    }
  }

  public get config() {
    return this._config
  }

  public set config(data: Config) {
    this._config = {
      ...this._config,
      ...data,
    }
  }
}

const globalConfig = new Configs()

export const GlobalConfig = () => {
  return globalConfig
}
